


























































































































































































































import { defineComponent, provide, watch } from "@vue/composition-api"
import UserChips from "@/components/UserChips.vue"
import TimeText from "@/components/TimeText.vue"
import { title } from "@/services/AppBarService"
import {
    MissionDetialModel,
    missionStateFormatter,
    missionTypeFormatter,
    missionTimeInfoFormatter,
    closeMission as callCloseMission,
} from "@/services/MissionsService"
import MissionFieldTable from "./MissionFieldTable.vue"
import MissionDetailSign from "./MissionDetailSign.vue"
import MissionBaseInfoEditor from "./MissionBaseInfoEditor.vue"
import { timeFormatter, timeFromNowFormatter } from "@/utilities/Formatter"
import {
    infoDialog,
    successDialog,
    errorDialog,
    closeDialog,
    DialogButtonType,
    ButtonResult,
    showDialog,
    DialogLevel,
    setMessage,
} from "@/services/DialogService"

export default defineComponent({
    name: "MissionDetail",
    components: {
        MissionFieldTable,
        MissionDetailSign,
        UserChips,
        TimeText,
        MissionBaseInfoEditor,
    },
    props: {
        id: {
            required: true,
            type: String,
        },
    },
    setup(props) {
        title.value = `任務檢視：${props.id}`
        const model = new MissionDetialModel(props.id)
        provide(MissionDetialModel.name, model)

        watch(
            () => model.mission.value,
            (value) => {
                title.value = `任務檢視：${props.id} ${value?.subject}`
            }
        )

        async function submitChanges() {
            try {
                infoDialog("送出中", "請稍後", DialogButtonType.None)
                await model.submitRecords((message) => setMessage(message))
                closeDialog()
                successDialog("異動成功")
                model.load()
            } catch (error) {
                console.error(error)
                closeDialog()
                errorDialog("異動失敗，請聯絡系統維護人員")
            }
        }

        async function closeMission() {
            const allFilled = model.mission.value?.allFieldFilled
            const { button } = await showDialog(
                "確定要關閉任務？",
                allFilled ? "" : "還有工項尚未填寫",
                allFilled ? DialogLevel.Info : DialogLevel.Warning,
                DialogButtonType.YesNo
            )
            if (button !== ButtonResult.Yes) return
            try {
                await callCloseMission(props.id)
                successDialog("成功關閉")
                model.load()
            } catch (error) {
                console.error(error)
                errorDialog("關閉失敗，請聯絡系統維護人員")
            }
        }

        return {
            missionTypeFormatter,
            mission: model.mission,
            setAllFieldDefaultValue: () => model.setAllFieldDefaultValue(),
            reload: () => model.load(),
            timeFormatter,
            missionStateFormatter,
            timeFromNowFormatter,
            changed: model.changed,
            submitChanges,
            missionTimeInfoFormatter,
            closeMission,
        }
    },
})
