









































































import { defineComponent, reactive, toRefs, watch } from "@vue/composition-api"
import UserPagingSelect from "@/components/UserPagingSelect.vue"
import DateTimeInput from "@/components/DateTimeInput.vue"
import dayjs from "dayjs"
import { errorDialog, successDialog } from "@/services/DialogService"
import { env } from "@/env"
import {
    MissionViewModelWithComputedInfo,
    putMissionBaseInfo,
} from "@/services/MissionsService"

export default defineComponent({
    name: "ModifyMissionBaseDialog",
    components: {
        UserPagingSelect,
        DateTimeInput,
    },
    props: {
        mission: {
            type: Object as () => MissionViewModelWithComputedInfo,
            required: true,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            dialog: false,
            subject: "",
            startTime: dayjs().startOf("minutes").toISOString(),
            overdueTime: dayjs().startOf("day").add(7, "day").toISOString(),
            description: "未填寫描述",
            managerIds: [] as string[],
            assigneeIds: [] as string[],
            cooperatorIds: [] as string[],
        })

        watch(
            () => state.dialog,
            (dialog) => {
                if (!dialog) return
                state.subject = props.mission.subject
                state.startTime = props.mission.startTime
                state.overdueTime = props.mission.overdueTime
                state.description = props.mission.description
                state.managerIds = props.mission.managerIds
                state.assigneeIds = props.mission.assigneeIds
                state.cooperatorIds = props.mission.cooperatorIds
            }
        )

        async function submit() {
            try {
                await putMissionBaseInfo(props.mission.id, {
                    subject: state.subject,
                    startTime: state.startTime,
                    overdueTime: state.overdueTime,
                    description: state.description,
                    managerIds: state.managerIds,
                    assigneeIds: state.assigneeIds,
                    cooperatorIds: state.cooperatorIds,
                })
                state.dialog = false
                successDialog("修改任務基本資訊成功")
                emit("sent")
            } catch (error) {
                errorDialog("修改任務基本資訊發生錯誤，請聯絡系統維護人員")
            }
            state.dialog = false
        }

        function close() {
            state.dialog = false
        }
        return {
            ...toRefs(state),
            submit,
            close,
        }
    },
})
