



























import { env } from "@/env"
import {
    MissionDetialModel,
    MissionFieldViewModel,
} from "@/services/MissionsService"
import {
    computed,
    defineComponent,
    inject,
    reactive,
    toRefs,
} from "@vue/composition-api"
import lodash from "lodash"
export default defineComponent({
    name: "FieldAttachViewer",
    props: {
        field: {
            type: Object as () => MissionFieldViewModel,
            required: true,
        },
        canMarkRemove: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const attaches = computed(
            () => props.field.latestRecord?.attachInfos ?? []
        )
        const removeAttach = computed(() => props.field.removeAttachIds)

        const missionDetial = inject<MissionDetialModel>(
            MissionDetialModel.name
        )!

        function downloadLink(attachId: string) {
            return `${env.MISSION_SERVICE}/api/Missions/${props.field.missionId}/MissionFields/${props.field.id}/LatestRecord/Attaches/${attachId}`
        }

        const state = reactive({
            dialog: false,
        })

        function trigger() {
            state.dialog = true
        }

        function switchMarkToRemove(removed: boolean, attachId: string) {
            let removeIds = lodash(props.field.removeAttachIds)
            if (removed) {
                removeIds = removeIds.push(attachId)
            } else {
                removeIds = removeIds.remove(attachId)
            }
            missionDetial.setFieldRemoveAttachIds(
                props.field.id,
                removeIds.value()
            )
        }

        return {
            ...toRefs(state),
            trigger,
            attaches,
            downloadLink,
            removeAttach,
            switchMarkToRemove,
        }
    },
})
