







































































































import {
    computed,
    defineComponent,
    inject,
    reactive,
    ref,
    toRefs,
} from "@vue/composition-api"
import { MissionDetialModel, MissionType } from "@/services/MissionsService"
import MissionFieldInput from "./MissionFieldInput.vue"
import MissionFieldAbnormalSwitch from "./MissionFieldAbnormalSwitch.vue"
import MissionFieldPictureInput from "./MissionFieldPictureInput.vue"
import MissionFieldAttachInput from "./MissionFieldAttachInput.vue"
import MissionFieldMemoInput from "./MissionFieldMemoInput.vue"
import FieldPictureViewer from "./FieldPictureViewer.vue"
import FieldAttachViewer from "./FieldAttachViewer.vue"
import lodash from "lodash"
import { hasPermission } from "@/services/AuthService"
import { FieldType } from "@/services/MissionTemplatesService"
export default defineComponent({
    name: "MissionFieldTable",
    components: {
        MissionFieldInput,
        MissionFieldAbnormalSwitch,
        MissionFieldPictureInput,
        FieldPictureViewer,
        MissionFieldMemoInput,
        MissionFieldAttachInput,
        FieldAttachViewer,
    },
    setup() {
        const tabElement = ref(null as null | HTMLElement)

        const missionDetial = inject<MissionDetialModel>(
            MissionDetialModel.name
        )!

        const state = reactive({
            page: 1,
            tabId: 0,
        })
        const rowPerPage = 50

        const pageProp = computed({
            get: () => state.page,
            set: (value) => {
                tabElement.value?.scrollIntoView()
                scrollBy({
                    top: -80,
                })
                state.page = value
            },
        })

        const filteredFields = computed(() =>
            missionDetial.missionFields.value === null
                ? []
                : lodash(missionDetial.missionFields.value!)
                      .filter((f) => {
                          switch (state.tabId) {
                              case 0:
                                  return true
                              case 1:
                                  return f.value === null
                              case 2:
                                  return f.value !== null
                              case 3:
                                  return f.abnormal
                              case 4:
                                  return !!f.memo
                              case 5:
                                  return f.abnormal || !!f.memo
                              case 6:
                                  return (
                                      !!f.latestRecord &&
                                      f.latestRecord.pictureIds.length > 0
                                  )
                              case 7:
                                  return f.type !== FieldType.NormalAbnormal
                              default:
                                  return false
                          }
                      })

                      .value()
        )

        const displayFields = computed(() =>
            lodash(filteredFields.value)
                .sortBy((f) => f.sortIndex)
                .drop((state.page - 1) * rowPerPage)
                .take(rowPerPage)
                .value()
        )

        const total = computed(() =>
            Math.ceil((filteredFields.value.length ?? 0) / rowPerPage)
        )

        const hasAbnormal = computed(
            () => missionDetial.mission.value?.type === MissionType.Inspect
        )

        function setDefaultValueBySpaceObjectId(id: string) {
            missionDetial.setDefaultValueBySpaceObjectId(id)
        }

        const canFill = computed(
            () =>
                (missionDetial.mission.value?.canFill ?? false) &&
                hasPermission("WEB_FILL_MISSION")
        )

        function changeTab(tabId: number) {
            state.page = 1
            state.tabId = tabId
        }

        return {
            tabElement,
            ...toRefs(state),
            displayFields,
            total,
            setDefaultValueBySpaceObjectId,
            canFill,
            hasAbnormal,
            changeTab,
            pageProp,
        }
    },
})
