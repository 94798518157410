





























import {
    MissionDetialModel,
    MissionFieldViewModel,
} from "@/services/MissionsService"
import { computed, defineComponent, inject } from "@vue/composition-api"
export default defineComponent({
    name: "MissionFieldPictureInput",
    props: {
        field: {
            type: Object as () => MissionFieldViewModel,
            required: true,
        },
    },
    setup(props) {
        const missionDetial = inject<MissionDetialModel>(
            MissionDetialModel.name
        )!

        const value = computed({
            get: () => props.field.uploadPictures,
            set: (value) => {
                missionDetial.setFieldUploadPictures(props.field.id, value)
            },
        })

        return {
            value,
            canFill: missionDetial.mission.value?.canFill ?? false,
        }
    },
})
