


































































import {
    getMissionSignaturePicture,
    MissionDetialModel,
    MissionState,
    removeSignature,
} from "@/services/MissionsService"
import UserChip from "@/components/UserChip.vue"
import Qrcode from "@/components/Qrcode.vue"
import Picture from "@/components/Picture.vue"
import {
    computed,
    defineComponent,
    inject,
    reactive,
    toRefs,
} from "@vue/composition-api"
import {
    ButtonResult,
    DialogButtonType,
    showDialog,
    warningDialog,
} from "@/services/DialogService"
import { hasAnyIdentity } from "@/services/AuthService"
export default defineComponent({
    components: {
        UserChip,
        Qrcode,
        Picture,
    },
    setup() {
        const missionDetial = inject<MissionDetialModel>(
            MissionDetialModel.name
        )!

        const state = reactive({
            showQrCodeDialog: false,
            qrcodeValue: "",
        })

        const signs = computed(
            () => missionDetial.mission.value?.missionSignatureFields ?? []
        )

        const missionOpenForSign = computed(
            () => missionDetial.mission.value?.state === MissionState.Opened
        )

        function addSignDialog(fieldId: string) {
            if (missionDetial.changed.value) {
                warningDialog("尚有工項異動尚未儲存", "請先儲存異動後再簽名")
                return
            }
            state.showQrCodeDialog = true
            state.qrcodeValue = JSON.stringify({
                missionId: missionDetial.id,
                signFieldId: fieldId,
            })
        }

        function closeQrCodeDialog() {
            state.showQrCodeDialog = false
            state.qrcodeValue = ""
            missionDetial.load()
        }

        function downloadPictureMethod(id: string) {
            const found = signs.value
                .flatMap((f) =>
                    f.missionSignatureRecords.map((r) => ({
                        fieldId: f.id,
                        recordId: r.id,
                    }))
                )
                .filter((i) => i.recordId == id)[0]

            return getMissionSignaturePicture(
                missionDetial.id,
                found.fieldId,
                id
            )
        }

        async function removeSign(recordId: string) {
            const result = await warningDialog(
                "確定要刪除簽名？",
                "此動作將無法復原。",
                DialogButtonType.YesNo
            )
            if (result.button !== ButtonResult.Yes) return

            await removeSignature(missionDetial.id, recordId)
            missionDetial.load()
        }

        return {
            signs,
            addSignDialog,
            closeQrCodeDialog,
            ...toRefs(state),
            downloadPictureMethod,
            hasAnyIdentity,
            missionOpenForSign,
            removeSign,
        }
    },
})
